<template>
    <activaitor
        @ok="$router.push('/admin/products/00000000-0000-0000-0000-000000000000')"
        placeholder="ابحث عن منتج محدد"
        @search="search"
        btnText="منتج جديد"
    ></activaitor>
</template>
<script>
import activaitor from "@Ekcore/components/EK-dialog/activaitor.vue";
export default {
    components: {
        activaitor
    },
    methods:{
        search(query) {
            this.$store.commit('Set_Search_Dto', {
                keys: ['name'],
                query
            })
        }
    }
};
</script>